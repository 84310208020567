<template>
  <Loader />
</template>

<script>
import Loader from "@/components/Loader.vue";
import { jwtDecode } from "jwt-decode";

export default {
  name: "callback",
  components: {
    Loader,
  },
  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token) {
      if (urlParams.get('init')) {
        const decoded = jwtDecode(token);

        localStorage.setItem("merchantId", decoded.app);
        localStorage.setItem("bearer", token);
      }
      else {
        localStorage.setItem("token", token);
      }

      window.location.href = "/";
    }
  },
};
</script>